import React from "react";
import Tabcompone from "./Tabcompone";

export default function CarouselWithTabs() {
  return (
    <div>
      <Tabcompone />
    </div>
  );
}
